import React, {useState} from 'react'
import { Link } from 'react-scroll'
import styles from './business.module.css'
import logoCircle from '../../assets/illustration/updated_circle_shape_logo.png'
import laptop from '../../assets/illustration/logo laptop.png'

function Business() {
    const [activeLink, setActiveLink] = useState("Home");
    const [hoveredLink, setHoveredLink] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // New state for menu visibility
  
    const handleLinkClick = (link) => {
      setActiveLink(link); // Update active link on click
      setIsMenuOpen(false); // Close the menu after a link is clicked
    };
  return (
    <>
        <section className={styles.businessSection} id='business'>
            <div className={styles.businessContainer}>
                <div className={styles.businessCard}>
                    <img src={logoCircle} className={styles.logocirclee} alt="pic" />
                    <img src={laptop} className={styles.logoLaptop} alt="pic" />
                </div>
                <div className={styles.businessCard}>
                    <h1>Your Business Partner</h1>
                    <p>M techub llc. stands at the intersection of innovation and excellence in the app development landscape. As a premier agency, we specialize in transforming ideas into dynamic digital solutions. </p>
                    <p>Our team of developers and strategists work collaboratively to craft applications that not only meet but exceed client expectations. With a focus on user-centric design and cutting-edge technology, M techub llc. is your trusted partner in navigating the digital future</p>
                    <div className={styles.businessBtnContainer}>
                        {/* <button className={styles.aboutBtn}>More About us</button> */}
                        <Link
              to="contactUs"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              onClick={() => handleLinkClick("Contact Us")}
            >
                        <button className={styles.contactBtn}>Contact us<i className={`${styles.forwardIcon} fa-solid fa-chevron-right`}></i></button>
                   </Link>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Business
