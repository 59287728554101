import React from 'react'
import styles from './pricingPackages.module.css'
import { BsCheckCircleFill } from 'react-icons/bs'

const PricingPackages = () => {
  return (
    <section className={styles.pricingSection} id="pricing">
      <div className={styles.pricingContainer}>
        <div className={styles.pricingHeader}>
          <h2>Pricing Packages</h2>
          <p>Choose the perfect plan for your business needs</p>
        </div>
        
        <div className={styles.pricingCards}>
          {/* Basic Package */}
          <div className={styles.pricingCard}>
            <div className={styles.cardHeader}>
              <h3>Basic</h3>
              <div className={styles.price}>
                <span className={styles.currency}>$</span>
                <span className={styles.amount}>300</span>
              </div>
              <p>Perfect for small businesses</p>
            </div>
            <div className={styles.features}>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>5 Pages Website</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Mobile Responsive Design</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Contact Form</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Basic SEO Setup</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Social Media Integration</span>
              </div>
            </div>
            <button className={styles.ctaButton}>Get Started</button>
          </div>

          {/* Professional Package */}
          <div className={`${styles.pricingCard} ${styles.featured}`}>
            <div className={styles.cardHeader}>
              <div className={styles.popularTag}>Most Popular</div>
              <h3>Professional</h3>
              <div className={styles.price}> 
                <span className={styles.currency}>$</span>
                <span className={styles.amount}>899</span> 
              </div>
              <p>Ideal for growing companies</p>
            </div>
            <div className={styles.features}>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>10 Pages Website</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Advanced Design Customization</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>E-commerce Integration</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Advanced SEO Optimization</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Content Management System</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Analytics Integration</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Priority Support</span>
              </div>
            </div>
            <button className={styles.ctaButton}>Get Started</button>
          </div>

          {/* Enterprise Package */}
          <div className={styles.pricingCard}>
            <div className={styles.cardHeader}>
              <h3>Enterprise</h3>
              <div className={styles.price}>
                <span className={styles.currency}>$</span>
                <span className={styles.amount}>3500</span>
                <span className={styles.period}>/mo</span>
              </div>
              <p>For large scale organizations</p>
            </div>
            <div className={styles.features}>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Unlimited Pages</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Custom Web Applications</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Advanced E-commerce Solutions</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Enterprise SEO Strategy</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Custom API Integration</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>24/7 Premium Support</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Performance Optimization</span>
              </div>
              <div className={styles.feature}>
                <BsCheckCircleFill className={styles.checkIcon} />
                <span>Security Hardening</span>
              </div>
            </div>
            <button className={styles.ctaButton}>Contact Us</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PricingPackages 