import React from 'react'
import styles from './services.module.css'
import firstImg from '../../assets/illustration/1.png'
import secondImg from '../../assets/illustration/web pic.png'
import thirdImg from '../../assets/illustration/web pic (3).png'
import fourthImg from '../../assets/illustration/6.png'
import fifthImg from '../../assets/illustration/6.png'
import sixthImg from '../../assets/illustration/6.png'

function Services() {
  return (
    <>
        <section className={styles.servicesSection} id="ourServices">
            <div className={styles.servicesContainer}>
                <div className={styles.servicesCardLeft}>
                    <h1>Dive into Diverse Service Offerings</h1>
                    <div className={styles.cardGlobalContainer}>
                        <i className="fa-regular fa-circle-check"></i>
                        <p>Tailored solutions, global impact</p>
                    </div>
                    <div className={styles.cardDrivingContainer}>
                        <i className="fa-regular fa-circle-check"></i>
                        <p>Crafting excellence, driving success</p>
                    </div>
                </div>
                <div className={styles.servicesCardRigth}>
                    <div className={styles.cards}>
                        <img src={firstImg} alt="img" />
                        <h1>App Development</h1>
                        <p>Transforming ideas into intuitive digital experiences.</p>
                        <span>Learn More</span>
                    </div>
                    <div className={styles.cards}>
                        <img src={secondImg} alt="img" />
                        <h1>Web Development</h1>
                        <p>Crafting digital platforms for optimal user experience</p>
                        <span>Learn More</span>
                    </div>
                    <div className={styles.cards}>
                        <img src={thirdImg} alt="img" />
                        <h1>Digital Marketing</h1>
                        <p>Transforming clicks into customers, elevating your brand digitally</p>
                        <span>Learn More</span>
                    </div>
                    <div className={styles.cards}>
                        <img src={fourthImg} alt="img" />
                        <h1>UI / UX Design</h1>
                        <p>From vision to interaction: crafting seamless designs</p>
                        <span>Learn More</span>
                    </div>
                    <div className={styles.cards}>
                        <img src={fifthImg} alt="img" />
                        <h1>SEO Optimization</h1>
                        <p>Boosting visibility and driving organic growth through strategic optimization</p>
                        <span>Learn More</span>
                    </div>
                    <div className={styles.cards}>
                        <img src={sixthImg} alt="img" />
                        <h1>Video Editing</h1>
                        <p>Creating compelling visual stories that captivate your audience</p>
                        <span>Learn More</span>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Services
