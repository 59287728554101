import React, { useState } from 'react';
import styles from './contact.module.css';
import dp from '../../assets/illustration/cont.png';
import arrow from '../../assets/images/arrow.png';

function Contact() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.message) newErrors.message = 'Message is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Handle form submission
      console.log('Form submitted:', formData);
    }
  };

  return (
    <>
      <section className={styles.contactSection} id="contactUs">
        <div className={styles.contactContainer}>
          <div className={styles.top}>
            <h1>Contact us</h1>
          </div>
          <div className={styles.middle}>
            <img src={dp} alt="img" />
          </div>
          <div className={styles.bottom}>
            <div className={styles.left}>
              <h1>Start your business journey better with Auroxa Tech Consulting</h1>
              <p>Schedule a 15 minute call to discuss your project and long term goals.</p>
            </div>
            <div className={styles.right}>
              <div className={styles.move}><img src={arrow} alt="img" width={300} /></div>
              <div><a target='_blank' href='https://calendly.com/auroxatechofficial/software-development-guideline'><button>Schedule a Meeting</button></a></div>
            </div>
          </div>
        </div>
      </section>
        
      {/* Contact Form */}
      <section className={styles.contactSection}>
        <div className={styles.contactContainer}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? styles.errorInput : ''}
              />
              {errors.name && <span className={styles.errorText}>{errors.name}</span>}
            </div>
            <div className={styles.formGroup}>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                className={errors.email ? styles.errorInput : ''}
              />
              {errors.email && <span className={styles.errorText}>{errors.email}</span>}
            </div>
            <div className={styles.formGroup}>
              <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                className={errors.message ? styles.errorInput : ''}
              />
              {errors.message && <span className={styles.errorText}>{errors.message}</span>}
            </div>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </section>
    </>
  );
}

export default Contact;
