import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Hero from '../components/Hero/Hero'
import Support from '../components/Support/Support'
import Business from '../components/Business/Business'
import Services from '../components/Services/Services'
import Industries from '../components/Industries/Industries'
import Partnership from '../components/partnership/Partnership'
import Project from '../components/Project/Project'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import styles from "../components/Hero/hero.module.css"
import PricingPackages from '../components/PricingPackages/PricingPackages'
import VideoFeedbacks from '../components/VideoFeedbacks/VideoFeedbacks'
import '../App.css'
function Home() {
  return (
    <>
      <Navbar />
     <div className={styles.heroSupportWrapper}>
     <Hero />
     <Support />
     </div>
      <Business />
      <Services />
     <Project />
     {/* <VideoFeedbacks /> */}
     <PricingPackages />
     <div className={styles.heroSupportWrapper}>
     <Partnership />
     </div>
      <Contact/>
      <Footer/>
    </>
  )
}

export default Home
