import React from 'react'
import styles from './hero.module.css'
// import heroGuy from '../../assets/images/insurance-hero-guy-2-188x300.png'
import heroGuy from '../../assets/illustration/hero-girl-with-gradient.png'
import gradient from '../../assets/images/white-gradient.jpg'
import illustration1 from '../../assets/illustration/web pic.png'
import illustration2 from '../../assets/illustration/web pic (1).png'
import illustration3 from '../../assets/illustration/web pic (2).png'
import illustration4 from '../../assets/illustration/web pic (3).png'
// import Animate from 'animate.css-react'
import 'animate.css';
import { useState, useEffect } from 'react'
import { Link } from 'react-scroll' 
function Hero() {
  const [activeLink, setActiveLink] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsMenuOpen(false);
  };

  return (
    <div className={styles.mainheroSection}>
      <section className={styles.heroSection} id="home">
        <div className={styles.heroContainer}>
          <div className={styles.heroLeft}>
            <h1>AI-Powered, Complete Digital Solutions for Every Business Need</h1>
            <p>Receive guidance on digital strategy, direct access to design and development experts, and a platform that’s successfully launched over 100+ apps, websites, and social campaigns globally</p>
            <div className={styles.heroBtnContainer}>
              <Link
                to="ourServices"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => handleLinkClick("Our Services")}
              > <button className={styles.exploreBtn}>Explore Our Services</button></Link>
              <a target='_blank' href='https://calendly.com/auroxatechofficial/software-development-guideline'> <button className={styles.startBtn}>Get Started Today</button> </a>
            </div>
          </div>
          <div className={styles.heroRight}>
            <img 
              src={heroGuy} 
              alt="Hero illustration" 
              className="animate__animated animate__fadeInUp" 
              style={{ animationDuration: '0.8s' }} 
            />
            {animate && (
              <>
                <img src={illustration1} className={`${styles.animatedImage}`} alt="Floating illustration 1" />
                <img src={illustration2} className={`${styles.animatedImage} ${styles.img2}`} alt="Floating illustration 2" />
                <img src={illustration3} className={`${styles.animatedImage} ${styles.img3}`} alt="Floating illustration 3" />
                <img src={illustration4} className={`${styles.animatedImage} ${styles.img4}`} alt="Floating illustration 4" />
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Hero
